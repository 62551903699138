import React, { useState } from "react";
import Page, {DualPage, LoadingPage} from "../../components/Page";
import {Helmet} from "react-helmet-async";
import TeamDetails from "./TeamDetails/TeamDetails";
import {useBackend} from "../../utility/Backend";
import Config from "../../utility/Config";
import {useUpdateSearchParams} from "../../utility/Utilities";
import { toAscii } from "../../utility/Utilities";
import fallbackLogo from "../../img/superettanLogo.png"; // TODO Create a good fallback for teams with no logo set
import "./Teams.css";
import {MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank} from "react-icons/md";
import {AiOutlineFileSearch} from "react-icons/ai";

function Teams () {

    const [searchParams, updateSearchParams, resetAllSearchParamsExcept] = useUpdateSearchParams();

    const [searchTeam, setSearchTeam] = useState("");

    const teamId =  searchParams.get("team_info");
    const leagueParam =  searchParams.get("leagues");
    const parsedTeamId = parseInt(teamId);

    const {data: allTeams, mutate} = useBackend("/team", {all_leagues: true});
    // TODO temporary switch season to 2023, since 2024 has not begun yet
    const {data: activeLeagueTeams} = useBackend("/team", {season: Config.seasons[0]})

    if (!allTeams || !activeLeagueTeams) return <LoadingPage/>;

    const refreshTeams = () => {
        mutate(allTeams);
    }

    let teamList = leagueParam === "all" ? allTeams.teams : activeLeagueTeams.teams;
    teamList.sort((a, b) => a.name.localeCompare(b.name))

    if (searchTeam !== "") {
        const asciiSearchTerm = toAscii(searchTeam)
        teamList = teamList.filter((team) => toAscii(team.name).includes(asciiSearchTerm));
    }

    const teamElements = teamList.map((team) => {
        const {id, import_id, import_source, logo_url, name, short_name, coach} = team;
        const importSource = import_source? import_source : "Import"
        const singleTeamLogo = logo_url? logo_url : fallbackLogo;
        return (
            <div 
                key={id} 
                onClick={() => updateSearchParams({"team_info": id})} 
                className={`single-team ${parsedTeamId === id? "chosen-team" : ""}`}
                >
                <div className="single-team-logo-cont">
                    <img src={singleTeamLogo} alt="team-logo" className="single-team-logo"/>
                </div>
                <div className="single-team-name-line"/>
                <div className="single-team-name">{name}</div>
                <div className="single-team-info">
                    <div className="sti-details"><span>Short name</span>{short_name}</div>
                    <div className="sti-details"><span>Coach</span>{coach}</div>
                    <div className="sti-details"><span>{importSource} id</span>{import_id}</div>
                </div>
            </div>
        )
    });

    const searchTeamInput = (
        <div className="search-team">
            <div className="search-team-title">
                Search
                <input
                    type="text"
                    onChange={(e) => setSearchTeam(e.target.value)}
                    className="search-team-input"/>
            </div>
            <div className="all-teams-filter-cont">
                {leagueParam? (
                    <div 
                        onClick={() => resetAllSearchParamsExcept(["team_info"])} 
                        className="all-teams-active">
                        <MdOutlineCheckBox className="all-teams-checked"/>
                        All existing teams ({allTeams.total})
                    </div>   
                ) : (
                    <div 
                        onClick={() => updateSearchParams("leagues", "all")} 
                        className="all-teams">
                        <MdOutlineCheckBoxOutlineBlank className="all-teams-unchecked"/>
                        All existing teams ({allTeams.total})
                    </div> 
                )}
            </div>
        </div>
    );

    const emptyEditPage = (
        <div className="flex-vertically-centered is-loading">
            <div>
                <AiOutlineFileSearch className="no-team-selected-icon"/>
                <br />
                None selected
            </div>
        </div>
    );

    return (
        <DualPage withLayoutSelection defaultLayout={0} rightPageQueryParam="team_info">
            <Page title="Teams">
                <Helmet>
                    <title>Teams</title>
                </Helmet>
                <div className="teams-container">
                    {searchTeamInput}
                    {teamElements}
                    {teamElements.length <= 0 && (<div className="no-teams-found">No teams found</div>)}
                </div>
            </Page>
            <Page>
                {teamId && (
                    <TeamDetails refresh={refreshTeams} teamId={teamId} activeLeagueTeams={activeLeagueTeams}/>
                )}
                {!teamId && emptyEditPage}
            </Page>
        </DualPage>
    );
}

export default Teams;