import React, {useState} from "react"
import { useBackend } from "../../utility/Backend";
import Backend from "../../utility/Backend";
import {LoadingPage} from "../../components/Page";
import { useUpdateSearchParams } from "../../utility/Utilities";
import { useLoginSession } from "../../stores/loginSession";
import { useFeedbackMessage } from "../../stores/FeedbackMessage";
import classNames from "classnames";
import { useMutateByRegex } from "../../utility/Utilities";
import ConfirmModal from "../../components/ConfirmModal";
import "./ExternalServer.css"
import { FaTrashAlt } from "react-icons/fa";

function ExternalServerDetails ({serverId}) {

    const {token} = useLoginSession();
    const {showFeedback} = useFeedbackMessage();
    const mutateByRegex = useMutateByRegex()
    const [,, resetAllSearchParamsExcept] = useUpdateSearchParams();

    const [isDeletingServer, setIsDeletingServer] = useState(false)

    const {data: externalServer} = useBackend("/external_server/" + serverId);
    
    if (!externalServer) return <LoadingPage/>

    const {id, address, default_dir, name, port, type} = externalServer

    const onDeleteServer = () => {
        const query = {access_token: token}

        Backend.delete("/external_server/" + id, query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to DELETE", error);
                    showFeedback("warning", "Failed to delete external server, " + error);
                } else {
                    console.log("Server deleted");
                    resetAllSearchParamsExcept([])
                    mutateByRegex(/^\/|(external_server)/)
                    showFeedback("success", "External server deleted successfully!");
                }
            });
        return false;
    }

    const deleteServerModal = (
        <ConfirmModal 
            isOpen 
            onClose={() => setIsDeletingServer(false)} 
            onConfirm={onDeleteServer} 
            confirmText = "Delete"
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><FaTrashAlt/></div>
                <div className="confirm-title">Do you really want to delete {name}?</div>
            </div>
        </ConfirmModal>
    )

    return (
        <div className="server-details-cont">
            <div className="data-info-cont">
                <div className="data-info-head-title">Server details</div>
                <div className="data-info">
                    <div className="data-info-title">Server name</div>
                    <div className="data-info-value">{name}</div>
                </div>
                <div className="data-info">
                    <div className="data-info-title">Type</div>
                    <div className="data-info-value">
                        <div className={classNames("server-single-type", {"sftp": type === "sftp"})}>
                            {type}
                        </div>
                    </div>
                </div>
                <div className="data-info">
                    <div className="data-info-title">Address</div>
                    <div className="data-info-value">{address}</div>
                </div>
                <div className="data-info">
                    <div className="data-info-title">Port</div>
                    <div className="data-info-value">{port}</div>
                </div>
                <div className="data-info">
                    <div className="data-info-title">Default directory</div>
                    <div className="data-info-value">{default_dir}</div>
                </div>
                <div className="confirm-cancel-btn-cont right margin-top">
                    <button onClick={() => setIsDeletingServer(true)} className="red-btn">
                        <FaTrashAlt className="icon-in-btn"/>
                        Delete Server
                    </button>
                </div>
            </div>
            {isDeletingServer && deleteServerModal}
        </div>
    )
}

export default ExternalServerDetails