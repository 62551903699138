import React, {useState, useRef, useEffect} from "react"
import { useLoginSession } from "../../../../stores/loginSession";
import { useBackend } from "../../../../utility/Backend";
import Config from "../../../../utility/Config";
import CustomModal from "../../../../components/CustomModal";
import { useCheckIsAdmin } from "../../../../utility/UserGroups";
import { ClickOutside, capitalizeFirstLetter } from "../../../../utility/Utilities";
import classNames from "classnames"
import { getSocialMediaLogo } from "./PrepareExport";
import { getAspectRatioName } from "./PrepareExport";
import {TiArrowSortedDown} from "react-icons/ti";
import {IoSettingsOutline} from "react-icons/io5";
import {LiaTimesSolid} from "react-icons/lia";
import "./EditingControls.css";

function PrepareExportModal ({
    graphic,
    externalServer,
    preset,
    aspectRatio,
    fps,
    cropping,
    bitrate,
    audioBitrate,
    audio,
    clipTransitions,
    aiClipping,
    setGraphic,
    setExternalServer,
    setPreset,
    setAspectRatio,
    setFps,
    setCropping,
    setBitrate,
    setAudioBitrate,
    setAudio,
    setClipTransitions,
    setAiClipping,
    onClickQueueExport,
    onCancel,
    multipleClips=false,
    prepareExportError=null,
}) {

    const {token} = useLoginSession();

    const {data: packagesData} = useBackend("/graphics/package", {access_token: token});
    const graphicPackages = packagesData?.graphics_packages || []
    const graphicPackagesList = graphicPackages.map((p) => p.name)

    const {data: externalServersData} = useBackend("/external_server", {access_token: token});
    const externalServers = externalServersData || []

    const graphicRef = useRef(null)
    const externalServerRef = useRef(null)
    const presetRef = useRef(null)
    const fpsRef = useRef(null)
    const transitionRef = useRef(null)
    const transitionTypeRef = useRef(null)
    const aspectRatioRef = useRef(null)
    const croppingRef = useRef(null)
    const bitrateRef = useRef(null)
    const audioBitrateRef = useRef(null)
    const audioRef = useRef(null)

    const [graphicDropdown, setGraphicDropdown] = useState(false)
    const [externalServerDropdown, setExternalServerDropdown] = useState(false)
    const [presetDropdown, setPresetDropdown] = useState(false)
    const [transitionDropdown, setTransitionDropdown] = useState(false)
    const [transitionTypeDropdown, setTransitionTypeDropdown] = useState(false)
    const [aspectRatioDropdown, setAspectRatioDropdown] = useState(false)
    const [fpsDropdown, setFpsDropdown] = useState(false)
    const [croppingDropdown, setCroppingDropdown] = useState(false)
    const [bitrateDropdown, setBitrateDropdown] = useState(false)
    const [audioBitrateDropdown, setAudioBitrateDropdown] = useState(false)
    const [audioDropdown, setAudioDropdown] = useState(false)
    const [advancedSetting, setAdvancedSetting] = useState(false)
    const [disableExportBtn, setDisableExportBtn] = useState(false)

    ClickOutside(graphicRef, setGraphicDropdown)
    ClickOutside(externalServerRef, setExternalServerDropdown)
    ClickOutside(presetRef, setPresetDropdown)
    ClickOutside(transitionRef, setTransitionDropdown)
    ClickOutside(transitionTypeRef, setTransitionTypeDropdown)
    ClickOutside(aspectRatioRef, setAspectRatioDropdown)
    ClickOutside(fpsRef, setFpsDropdown)
    ClickOutside(croppingRef, setCroppingDropdown)
    ClickOutside(bitrateRef, setBitrateDropdown)
    ClickOutside(audioBitrateRef, setAudioBitrateDropdown)
    ClickOutside(audioRef, setAudioDropdown)

    const [isFsAdmin,] = useCheckIsAdmin()
    const isShl = Config.association === "SHL"
    const isDemo = Config.association === "EL"
    const multipleAudioTracks = isShl || isDemo

    // TODO
    // const graphicList = isShl ? ["demo"] : ["demo", "demo_match", "sef", "sef_game_graphic"]
    const graphicList = graphicPackagesList.length !== 0 ? graphicPackagesList : ["demo"]
    const presetList = ["instagram_feed", "instagram_reels", "facebook_feed", "tiktok", "snapchat"];
    const aspectRatioList = ["9:16", "4:5", "1:1"]
    const fpsList = [25, 30, 50]
    const croppingList = ["center", "smart"]
    const transitionList = ["smoothleft", "smoothright", "circlecrop", "fade"]
    const bitrateList = [3.5, 4, 4.5, 5];
    const audioBitrateList = ["65k", "128k", "256k", "320k"]

    const socialMediaName = (p) => {
        const name = capitalizeFirstLetter(p.replace("_", " "))
        if (p === "instagram_feed") return name + " (1:1)"
        if (p === "instagram_reels") return name + " (9:16)"
        if (p === "facebook_feed") return name + " (4:5)"
        if (p === "tiktok") return name + " (9:16)"
        if (p === "snapchat") return name + " (9:16)"
    }

    const graphicTypeToName = (graphic) => {
        if (graphic === "demo_match") return "Demo match"
        if (graphic === "sef_game_graphic") return "SEF match highlights"
        if (graphic === "sef") return "SEF'"
        else return capitalizeFirstLetter(graphic)
    }

    const transitionTypeName = (type) => {
        if (type === "smoothleft") return "smooth left"
        if (type === "smoothright") return "smooth right"
        if (type === "circlecrop") return "circle crop"
        else return type
    }

    const onClickExport = () => {
        setDisableExportBtn(true)
        onClickQueueExport()
    }

    useEffect(() => {
        const resetDisableExportBtn = setInterval(()=> {
            if (disableExportBtn) setDisableExportBtn(false)
        }, 500)
        return () => clearInterval(resetDisableExportBtn);
    }, [disableExportBtn])

    const croppingName = (name) => {
        if (name === "center") return "Center (Static frame center)"
        if (name === "smart") return "Smart crop (Dynamic saliency detection)"
        else return capitalizeFirstLetter(name)
    }

    const handleChangeGraphic = (graphic=null) => {
        setGraphic(graphic)
        setGraphicDropdown(!graphicDropdown)
    }

    const handleChangeExternalServer = (server=null) => {
        setExternalServer(server)
        setExternalServerDropdown(!externalServerDropdown)
    }

    const handleChangePreset = (preset=null) => {
        setPreset(preset)
        setPresetDropdown(!presetDropdown)
    }

    const handleChangeTransition = (transition) => {
        setClipTransitions(transition)
        setTransitionDropdown(false)
        setTransitionTypeDropdown(false)
    }

    const handleChangeAspectRatio = (aspectRatio=null) => {
        setAspectRatio(aspectRatio)
        setAspectRatioDropdown(!aspectRatioDropdown)
    }

    const handleChangeFps = (fps=null) => {
        setFps(fps)
        setFpsDropdown(!fpsDropdown)
    }

    const handleChangeCropping = (cropping=null) => {
        setCropping(cropping)
        setCroppingDropdown(!croppingDropdown)
    }

    const handleChangeBitrate = (bitrate=null) => {
        setBitrate(bitrate)
        setBitrateDropdown(!bitrateDropdown)
    }

    const handleChangeAudioBitrate = (audioBitrate) => {
        setAudioBitrate(audioBitrate)
        setAudioBitrateDropdown(!audioBitrateDropdown)
    }

    const handleChangeAudio = (audio=null) => {
        setAudio(audio)
        setAudioDropdown(!audioDropdown)
    }

    const handleChangeAiClipping = () => {
        setAiClipping(!aiClipping)
        // setAiClippingDropdown(!aiClippingDropdown)
    }

    const presetOptions = (
        <div className="input-container">  
            <label className="input-title">Preset</label>
            <div ref={presetRef} className="dropdown-menu-cont">
                <div onClick={() => setPresetDropdown(!presetDropdown)} className="upload-dropdown-title">
                    {preset ? (
                        <div className="selected-preset">
                            <img src={getSocialMediaLogo(preset)} alt="social media logo" className="social-media-logo"/>
                            {socialMediaName(preset)}
                        </div>
                    ) : "Original"}
                    <TiArrowSortedDown/>
                </div>
                {presetDropdown && (
                    <div className="dropdown-list-cont">
                        <div
                            className={classNames("dropdown-item-single", {"active": !preset})}
                            onClick={() => handleChangePreset(null)}>
                            Original
                        </div>
                        {presetList.map((p) => {
                            return (
                                <div
                                    key={p} 
                                    className={classNames("dropdown-item-single", {"active": preset === p})}
                                    onClick={() => handleChangePreset(p)}>
                                    <img src={getSocialMediaLogo(p)} alt="social media logo" className="social-media-logo"/>
                                    {socialMediaName(p)}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    );

    const graphicOptions = (
        <div className="input-container">  
            <label className="input-title">Graphic package</label>
            <div ref={graphicRef} className="dropdown-menu-cont">
                <div onClick={() => setGraphicDropdown(!graphicDropdown)} className="upload-dropdown-title">
                    {graphic ? graphicTypeToName(graphic) : "None"}
                    <TiArrowSortedDown/>
                </div>
                {graphicDropdown && (
                    <div className="dropdown-list-cont">
                        <div
                            className={classNames("dropdown-item-single", {"active": !graphic})}
                            onClick={() => handleChangeGraphic(null)}>
                            None
                        </div>
                        {graphicList.map((g) => {
                            return (
                                <div
                                    key={g} 
                                    className={classNames("dropdown-item-single", {"active": graphic === g})}
                                    onClick={() => handleChangeGraphic(g)}>
                                    {graphicTypeToName(g)}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    );

    const externalServerOptions = isFsAdmin && (
        <div className="input-container">  
            <label className="input-title">External server</label>
            <div ref={externalServerRef} className="dropdown-menu-cont">
                <div onClick={() => setExternalServerDropdown(!externalServerDropdown)} className="upload-dropdown-title">
                    {externalServer ? `${externalServer.name} (${externalServer.type})` : "None"}
                    <TiArrowSortedDown/>
                </div>
                {externalServerDropdown && (
                    <div className="dropdown-list-cont">
                        <div
                            className={classNames("dropdown-item-single", {"active": !externalServer})}
                            onClick={() => handleChangeExternalServer(null)}>
                            None
                        </div>
                        {externalServers.map((s) => {
                            return (
                                <div
                                    key={s.id} 
                                    className={classNames("dropdown-item-single between", {"active": externalServer?.id === s.id})}
                                    onClick={() => handleChangeExternalServer(s)}>
                                    {s.name}
                                    <div className={classNames("dropdown-external-server-type", {"sftp": s.type === "sftp"})}>
                                        {s.type}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    );

    const transitionTypeOptions = multipleClips && (
        <div className="input-container">
            <label className="input-title">Transition type</label>
            <div ref={transitionTypeRef} className="dropdown-menu-cont">
                <div onClick={() => setTransitionTypeDropdown(!transitionTypeDropdown)} className="upload-dropdown-title">
                    {clipTransitions ? capitalizeFirstLetter(transitionTypeName(clipTransitions)) : "None"}
                    <TiArrowSortedDown/>
                </div>
                {transitionTypeDropdown && (
                    <div className="dropdown-list-cont">
                        <div
                            className={classNames("dropdown-item-single", {"active": !clipTransitions})}
                            onClick={() => handleChangeTransition(null)}>
                            None
                        </div>
                        {transitionList.map((t) => {
                            return (
                                <div
                                    key={t}
                                    className={classNames("dropdown-item-single", {"active": clipTransitions === t})}
                                    onClick={() => handleChangeTransition(t)}>
                                    {capitalizeFirstLetter(transitionTypeName(t))}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )

    const aspectRatioOptions = (
        <div className="input-container">  
            <label className="input-title">Aspect ratio</label>
            <div ref={aspectRatioRef} className="dropdown-menu-cont">
                <div onClick={() => setAspectRatioDropdown(!aspectRatioDropdown)} className="upload-dropdown-title">
                    {aspectRatio ? getAspectRatioName(aspectRatio) : "Original (16:9)"}
                    <TiArrowSortedDown/>
                </div>
                {aspectRatioDropdown && (
                    <div className="dropdown-list-cont">
                        <div
                            className={classNames("dropdown-item-single", {"active": !aspectRatio})}
                            onClick={() => handleChangeAspectRatio(null)}>
                            Original (16:9)
                        </div>
                        {aspectRatioList.map((a) => {
                            return (
                                <div
                                    key={a} 
                                    className={classNames("dropdown-item-single", {"active": aspectRatio === a})}
                                    onClick={() => handleChangeAspectRatio(a)}>
                                    {getAspectRatioName(a)}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    );

    const fpsOptions = preset && (
        <div className="input-container">
            <label className="input-title">FPS</label>
            <div ref={fpsRef} className="dropdown-menu-cont">
                <div onClick={() => setFpsDropdown(!fpsDropdown)} className="upload-dropdown-title">
                    {fps}
                    <TiArrowSortedDown/>
                </div>
                {fpsDropdown && (
                    <div className="dropdown-list-cont">
                        {fpsList.map((f) => {
                            return (
                                <div
                                    key={f} 
                                    className={classNames("dropdown-item-single", {"active": fps === f})}
                                    onClick={() => handleChangeFps(f)}>
                                    {f}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )

    const croppingOptions = (
        <div className="input-container">
            <label className="input-title">Cropping</label>
            <div ref={croppingRef} className="dropdown-menu-cont">
                <div onClick={() => setCroppingDropdown(!croppingDropdown)} className="upload-dropdown-title">
                    {croppingName(cropping)}
                    <TiArrowSortedDown/>
                </div>
                {croppingDropdown && (
                    <div className="dropdown-list-cont">
                        {croppingList.map((c) => {
                            return (
                                <div
                                    key={c} 
                                    className={classNames("dropdown-item-single", {"active": cropping === c})}
                                    onClick={() => handleChangeCropping(c)}>
                                    {croppingName(c)}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
            {cropping === "smart" && (
                <div className="option-extra-message space-top">
                    When changing resolution, let our AI models find a better cropping window with focus on the ball, puck or similar.
                </div>
            )}
        </div>
    )

    const transitionOptions = multipleClips && (
        <div className="input-container">
            <label className="input-title">Transition</label>
            <div ref={transitionRef} className="dropdown-menu-cont">
                <div onClick={() => setTransitionDropdown(!transitionDropdown)} className="upload-dropdown-title">
                    {clipTransitions ? "Enabled" : "Disabled"}
                    <TiArrowSortedDown/>
                </div>
                {transitionDropdown && (
                    <div className="dropdown-list-cont">
                        <div
                            className={classNames("dropdown-item-single", {"active": clipTransitions})}
                            onClick={() => handleChangeTransition("smoothleft")}>
                            Enabled
                        </div>
                        <div
                            className={classNames("dropdown-item-single", {"active": !clipTransitions})}
                            onClick={() => handleChangeTransition(null)}>
                            Disabled
                        </div>
                    </div>
                )}
            </div>
        </div>
    )

    const bitrateOptions = (
        <div className="input-container">  
            <label className="input-title">Bitrate</label>
            <div ref={bitrateRef} className="dropdown-menu-cont">
                <div onClick={() => setBitrateDropdown(!bitrateDropdown)} className="upload-dropdown-title">
                    {bitrate ? `${bitrate} Mbps` : "Original"}
                    <TiArrowSortedDown/>
                </div>
                {bitrateDropdown && (
                    <div className="dropdown-list-cont">
                        <div
                            className={classNames("dropdown-item-single", {"active": !bitrate})}
                            onClick={() => handleChangeBitrate(null)}>
                            Original
                        </div>
                        {bitrateList.map((b) => {
                            return (
                                <div
                                    key={b} 
                                    className={classNames("dropdown-item-single", {"active": bitrate === b})}
                                    onClick={() => handleChangeBitrate(b)}>
                                    {b} Mbps
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    );

    const audioBitrateOptions = (
        <div className="input-container">  
            <label className="input-title">Audio bitrate</label>
            <div ref={audioBitrateRef} className="dropdown-menu-cont">
                <div onClick={() => setAudioBitrateDropdown(!audioBitrateDropdown)} className="upload-dropdown-title">
                    {audioBitrate ? `${audioBitrate}` : "Original"}
                    <TiArrowSortedDown/>
                </div>
                {audioBitrateDropdown && (
                    <div className="dropdown-list-cont">
                        <div
                            className={classNames("dropdown-item-single", {"active": !audioBitrate})}
                            onClick={() => handleChangeAudioBitrate(null)}>
                            Original
                        </div>
                        {audioBitrateList.map((a) => {
                            return (
                                <div
                                    key={a} 
                                    className={classNames("dropdown-item-single", {"active": audioBitrate === a})}
                                    onClick={() => handleChangeAudioBitrate(a)}>
                                    {a}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    );
               
    const audioOptions = (
        <div className="input-container">  
            <label className="input-title">Audio track</label>
            <div ref={audioRef} className="dropdown-menu-cont">
                <div onClick={() => setAudioDropdown(!audioDropdown)} className="upload-dropdown-title">
                    {audio === null && "All"}
                    {audio === "0" && "Track 1"}
                    {audio > 0 && `Track ${audio+1}`}
                    <TiArrowSortedDown/>
                </div>
                {audioDropdown && (
                    <div className="dropdown-list-cont">
                        <div
                            className={classNames("dropdown-item-single", {"active": !audio})}
                            onClick={() => handleChangeAudio(null)}>
                            All
                        </div>
                        <div
                            className={classNames("dropdown-item-single", {"active": audio === 0})}
                            onClick={() => handleChangeAudio("0")}>
                            Track 1
                        </div>
                        <div
                            className={classNames("dropdown-item-single", {"active": audio === 1})}
                            onClick={() => handleChangeAudio(1)}>
                            Track 2
                        </div>
                    </div>
                )}
            </div>
        </div>
    );

    const aiClippingOptions = (
        <div>  
            <div className="input-container-toggle">
                <label className="toggle-title">AI clipping</label>
                <div onClick={handleChangeAiClipping} className={classNames("toggle-switch", {"active": aiClipping})}>
                    <div className="toggle-button"></div>
                </div>
            </div>
            <div className="option-extra-message clear-left space-top">Let our AI models find a better start and end of the video and clip the video accordingly.</div>
        </div>
    )

    const advancedSettingOptions = (
        <div className="advanced-prepare-export">
            {aspectRatioOptions}
            {fpsOptions}
            {croppingOptions}
            {transitionTypeOptions}
            {multipleAudioTracks && audioOptions}
            {bitrateOptions}
            {audioBitrateOptions}
            {aiClippingOptions}
        </div>
    )

    return (
        <CustomModal isOpen onRequestClose={onCancel} className={advancedSetting ? "export-wide" : "export-narrow"}>
            <div className="prepare-export-cont">
                <div className="prepare-export-title">Prepare export</div>
                <div className="prepare-export-and-advanced">
                    <form className="prepare-export-form">
                        {presetOptions}
                        {graphicOptions}
                        {externalServerOptions}
                        {transitionOptions}
                        <div onClick={() => setAdvancedSetting(!advancedSetting)} className="advanced-export-btn">
                            {advancedSetting ? <LiaTimesSolid className="advanced-close-icon"/> : <IoSettingsOutline className="advanced-setting-icon"/>}
                            Advanced settings
                        </div>
                        {prepareExportError && (
                            <div className="warning-msg">
                                {prepareExportError}
                            </div>
                        )}
                    </form>
                    {advancedSettingOptions}
                </div>
                <div className="confirm-cancel-btn-cont right">
                    <button disabled={disableExportBtn} className="green-btn" onClick={onClickExport}>
                        Prepare export
                    </button>
                    <button onClick={onCancel}>
                        Cancel
                    </button>
                </div>
            </div>
        </CustomModal>
    )
}

export default PrepareExportModal