import React from "react"
import { useBackend } from "../../utility/Backend";
import Page, {DualPage, LoadingPage} from "../../components/Page";
import { Helmet } from "react-helmet-async";
import { useUpdateSearchParams } from "../../utility/Utilities";
import CreateExternalServer from "./CreateExternalServer";
import ExternalServerDetails from "./ExternalServerDetails";
import { useCheckIsAdmin } from "../../utility/UserGroups";
import classNames from "classnames";
import "./ExternalServer.css"
import { FaServer } from "react-icons/fa";

function ExternalServer () {

    const {data: externalServers, mutate} = useBackend("/external_server");
    
    const [searchParams, updateSearchParams, resetAllSearchParamsExcept] = useUpdateSearchParams();
    const [isFsAdmin,] = useCheckIsAdmin()

    if (!externalServers) return <LoadingPage/>
    if (!isFsAdmin) return <div>You have no access to view this page.</div>
    
    const serverParams = searchParams.get("server")
    const createServerParam = serverParams === "create"
    const showServerDetails = !!serverParams && serverParams !== "create"

    const createServer = () => {
        updateSearchParams({"server": "create"})
    }

    const renderServers = (
        <div className="external-server-list">
            {externalServers.map((s) => {
                return (
                    <div 
                        key={s.id} 
                        onClick={() => updateSearchParams({"server": s.id})}
                        className={classNames("external-server-single", {"active": s.id === parseInt(serverParams)})}
                        >
                        <div className="server-single-name">
                            {s.name}
                        </div>
                        <div className={classNames("server-single-type", {"sftp": s.type === "sftp"})}>
                            {s.type}
                        </div>
                    </div>
                )
            })}
        </div>
    )

    const emptyServerPage = (
        <div className="flex-vertically-centered is-loading">
            <div>
                <FaServer className="no-server-selected-icon"/>
                <br />
                None selected
            </div>
        </div>
    )

    return (
        <DualPage withLayoutSelection defaultLayout={0} rightPageQueryParam="server">
            <Page title="Server">
                <Helmet><title>Server</title></Helmet>
                <div className="create-external-server-btn">
                    <button onClick={createServer} className="green-hover-btn">
                        Create server
                    </button>
                </div>
                {renderServers}
            </Page>
            <Page title={createServerParam ? "create external server" : "external server details"}>
                {createServerParam && (
                    <CreateExternalServer 
                        onCancel={() => resetAllSearchParamsExcept([])} 
                        mutate={() => mutate(externalServers)}/>
                )}
                {showServerDetails && <ExternalServerDetails serverId={serverParams}/>}
                {!serverParams && emptyServerPage}
            </Page>
        </DualPage>
    )
}

export default ExternalServer