import React, {useEffect, useState} from "react"
import Backend from "../../utility/Backend";
import { useUpdateSearchParams } from "../../utility/Utilities";
import { useLoginSession } from "../../stores/loginSession";
import { useFeedbackMessage } from "../../stores/FeedbackMessage";
import SmallDropdown from "../../components/SmallDropdown";
import { showErrorMessage } from "../../utility/Utilities";
import "./ExternalServer.css"

function CreateExternalServer ({onCancel, mutate}) {

    const {token} = useLoginSession()
    const {showFeedback} = useFeedbackMessage()
    const [,,resetAllSearchParamsExcept] = useUpdateSearchParams()

    const [serverName, setServerName] = useState("")
    const [type, setType] = useState("FTP")
    const [port, setPort] = useState(21)
    const [address, setAddress] = useState("")
    const [defaultDirectory, setDefaultDirectory] = useState("/")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [privateKey, setPrivateKey] = useState("")
    const [userGroupId, setUserGroupId] = useState("")
    const [hasInputError, setHasInputError] = useState(false)

    // Regular expression for PEM format
    const pemRegex = /^-----BEGIN (RSA|DSA|EC|OPENSSH) PRIVATE KEY-----(.|\n)*?-----END (RSA|DSA|EC|OPENSSH) PRIVATE KEY-----$/;
    const validPrivateKey = pemRegex.test(privateKey)

    const validServerName = serverName.length !== 0
    const validUsername = username.length !== 0 && !username.includes(" ")
    const validPassword = password.length >= 5 
    const validDirectory = defaultDirectory.charAt(0) === "/"
    const ftpFormCompleted = validServerName && validUsername && validPassword && validDirectory
    const sftpFormCompleted = validServerName && validUsername && validPrivateKey && validDirectory

    useEffect(() => {
        if (type === "FTP") setPort(21)
        if (type === "SFTP") setPort(22)
    }, [type])

    useEffect(() => {
        if (hasInputError) {
            if (type === "FTP" && ftpFormCompleted) setHasInputError(false)
            if (type === "SFTP" && sftpFormCompleted) setHasInputError(false)
        }
    }, [ftpFormCompleted, sftpFormCompleted])

    // useEffect(() => {
    //     if (!hasInputError) return

    //     if (hasInputError) {
    //         const inputCompleted = serverName.length >= 5
    //     }
    // }, [])

    const resetCreateServer = () => {
        setServerName("")
        setType("ftp")
        setPort(21)
        setAddress("")
        setDefaultDirectory("/")
        setUsername("")
        setPassword("")
        setPrivateKey("")
        setUserGroupId("")
        setHasInputError(false)
        onCancel()
    }
    
    const onCreateExternalServer = async () => {

        if (type === "FTP" && !ftpFormCompleted) {
            setHasInputError(true)
            return
        }
        if (type === "SFTP" && !sftpFormCompleted) {
            setHasInputError(true)
            return
        }

        const newServerProps = {
            name: serverName,
            type: type.toLowerCase(),
            address: address,
            port: port,
            default_dir: defaultDirectory,
            credentials: {
                username: username,
                password: password,
            }
        }

        if (type === "sftp" && privateKey) {
            newServerProps.credentials.properties.private_key = privateKey
        }

        if (type === "ftp" && password) {
            newServerProps.credentials.properties.password = password
        }

        if (userGroupId) {
            newServerProps.usergroup_id = userGroupId
        }

        console.log("creating new external server", newServerProps)

        const {error} = await Backend.post("/external_server", JSON.stringify(newServerProps), {access_token: token})

        if (error) {
            console.error("Failed to POST", error);
            showFeedback("warning", "Failed to create external server, " + error);
        } else {
            mutate()
            resetCreateServer()
            resetAllSearchParamsExcept([])
            showFeedback("success", "External server created successfully!");
            console.log("New external server created");
        }
    }

    const createServerForm = (
        <div className="create-external-server-form">
            <div className="input-container">
                <label className="input-title">Server name</label>
                <input
                    type="text"
                    onChange={(e) => setServerName(e.target.value)}
                    value={serverName}/>
                {hasInputError && showErrorMessage("Choose a server name", !validServerName)}
            </div>
            <div className="input-container stream-profile-input">
                <label htmlFor="" className="input-title">Server type</label>
                <SmallDropdown 
                    value={type}
                    options={["FTP", "SFTP"]}
                    onChange={(v) => setType(v)}/>
            </div>
            <div className="input-container">
                <label className="input-title">Address</label>
                <input
                    type="text"
                    placeholder="example.ftp.com"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}/>
                {hasInputError && showErrorMessage("Address min 5 characters", address.length < 5)}
            </div>
            <div className="input-container">
                <label className="input-title">Port</label>
                <div className="external-server-port">{port}</div>
            </div>
            <div className="input-container">
                <label className="input-title">Default directory</label>
                <input
                    type="text"
                    onChange={(e) => setDefaultDirectory(e.target.value)}
                    value={defaultDirectory}/>
                {hasInputError && showErrorMessage("Invalid directory (example: /Downloads)", !validDirectory)}
            </div>
            <div className="input-container">
                <label className="input-title">Username</label>
                <input
                    type="text"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}/>
                {hasInputError && showErrorMessage("Username is empty or contains space", !validUsername)}
            </div>
            {type === "FTP" && (
                <div className="input-container">
                    <label className="input-title">Password</label>
                    <input
                        type="text"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}/>
                    {hasInputError && showErrorMessage("please choose a password (min 5 characters)", !validPassword)}
                </div>
            )}
            {type === "SFTP" && (
                <div className="input-container">
                    <label className="input-title">Private key</label>
                    <textarea
                        className="server-private-key-input"
                        placeholder="Private key in PEM format"
                        rows="5"
                        value={privateKey}
                        onChange={(e) => setPrivateKey(e.target.value)}>
                    </textarea>
                    {hasInputError && showErrorMessage("Invalid PEM format", !validPrivateKey)}
                </div>
            )}
            <div className="input-container">
                <label className="input-title">Usergroup id</label>
                <input
                    type="text"
                    onChange={(e) => setUserGroupId(e.target.value)}
                    value={userGroupId}/>
            </div>
        </div>
    )
    
    return (
        <div className="create-external-server-cont">
            {createServerForm}
            <div className="confirm-cancel-btn-cont between margin-top">
                <button type="button" disabled={hasInputError} onClick={onCreateExternalServer} className="green-btn">Create</button>
                <button type="button" onClick={resetCreateServer}>Cancel</button>
            </div>
        </div>
    )
}

export default CreateExternalServer